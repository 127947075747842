<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-patient-bar @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar>
      </v-col>
      <v-col cols="12" v-if="currentPatient">
        <v-card>
          <v-document-list :hn="currentPatient.hn" folder="default"></v-document-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import patientLoader from '@/components/mixins/patientLoader'

export default {
  mixins: [patientLoader],
}
</script>

<style lang="css" scoped>
</style>
